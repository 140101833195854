<template>
  <div class="output">
    <van-nav-bar
      title="今日产量"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <scroll class="product_scroll" :onLoadMore="onLoadMore">
      <div class="output_k" v-if="outputList.length == 0">
        <div class="conten_empty_img">
          <img src="../../common/img/kong.png" />
        </div>
        <div class="establish">
          今天还没有报工任务哦，快去
          <a @click="toWorkOrder()">
            <router-link to="">新建报工 </router-link>
          </a>
          吧!
        </div>
      </div>
      <div v-else>
        <div class="output_item" v-for="item in outputList" :key="item.id">
          <div class="product_name">
            <div class="name">产品名称：</div>
            <div class="product_name_item">
              {{ item.wpName }}
            </div>
          </div>
          <div class="all_output_item">
            <div class="item">
              <div class="item_name">计划数</div>
              <div class="item_num">{{ item.planCounts }}</div>
            </div>
            <div class="item">
              <div class="item_name">良品数</div>
              <div class="item_num">
                {{ item.goodProductCounts }}
              </div>
            </div>
            <div class="item">
              <div class="item_name">不良品数</div>
              <div class="item_num stamp">
                {{ item.allocationState }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapGetters } from "vuex";
import { getTodayWordOrder } from "@/api/work";
export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      outputList: [],
    };
  },
  created() {
    ChangePageTitle("今日产量");
    this.getTodayWordOrder();
  },
  methods: {
    //跳转去新建工单
    toWorkOrder() {
      window.location.href = "/reportWork1";
    },
    onClickLeft() {
      this.$router.push("/");
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getTodayWordOrder() {
      const params = {
        departmentCode: this["departmentCode"],
      };
      const res = await getTodayWordOrder(params);
      this.outputList = res.records;
    },
  },
  components: {
    scroll,
  },
};
</script>
<style lang="less" scoped>
.output {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .product_scroll {
    width: 100vw;
    padding-top: 100px;
    .output_k {
      width: 100vw;
      .conten_empty_img {
        margin: auto;
        width: 40vw;
        margin-top: 30%;
        img {
          width: 100%;
        }
      }
      .establish {
        font-size: 30px;
        text-align: center;
        line-height: 1;
        color: #999;
        margin-top: 20px;
        a {
          color: blue;
        }
      }
    }
    .output_item {
      width: 95%;
      margin: auto;
      background-color: #fff;
      border-radius: 10px;
      padding: 16px 20px;
      margin-top: 30px;
      font-size: 30px;
      .product_name {
        display: flex;
        justify-content: start;
        color: black;
        align-items: center;
        .product_name_item {
          margin-left: 5px;
        }
      }
      .all_output_item {
        display: flex;
        margin-top: 20px;
        .item {
          flex: 1;
          display: flex;
          flex-direction: column;
          .item_num {
            margin-top: 20px;
            color: black;
          }
          .stamp {
            color: red;
          }
          .work_num {
            color: blue;
          }
        }
      }
    }
  }
}
</style>